import classNames from 'classnames';
import { Store } from 'json-api-models';
import { isArray, isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';

import { StatisticsInfluencerWithStatisticsModel } from 'api-models';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import useRestCollection from 'hooks/useRestCollection';
import { formatNumber } from 'shared/helpers/Chart/chart-util';
import { useAppSelector } from 'views/DataLibrary/hooks';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

import Styled from './ByInfluencerTableItem.style';

/**
 * Influencer table list item
 * @returns {JSX.Element}
 */
const ByInfluencerTableItem = (props: { influencer: StatisticsInfluencerWithStatisticsModel; i: number }): JSX.Element => {
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const [isOpenDetails, setIsOpenDetails] = useState<boolean>(false);
	const [displayRealValue, setDisplayRealValue] = useState<boolean>(false);
	const [selectedValue, setSelectedValue] = useState<number | null>(null);

	const { influencer, i } = props;
	const noAssignments = influencer.attributes.assignments === 0;

	const getParams = () => {
		const url = [];
		for (const key in dashboardFilter) {
			const value = dashboardFilter[key as keyof DashboardFilters];
			if (isArray(value)) {
				if (!isEmpty(value)) {
					url.push(`${key}=${value.join(',')}`);
				}
			} else {
				if (value) {
					url.push(`${key}=${value}`);
				}
			}
		}

		return url.join('&');
	};
	const assignmentDetailModels = new Store();

	// TODO make sure this works.. or change to apiManager
	const { data: assignmentDetails, error } = useRestCollection<{
		name: string;
		reach: number;
		impressions: number;
		engagement: number;
		reachRate: number;
		actualEngagementRate: number;
		averageReachRate: number;
	}>(isOpenDetails && props.influencer.links?.influencerAssignments ? `${props.influencer.links.influencerAssignments}?${getParams()}` : '');

	if (assignmentDetails) {
		assignmentDetailModels.sync(assignmentDetails);
	}

	useEffect(() => {
		return () => {
			setSelectedValue(null);
			setDisplayRealValue(false);
		};
	}, []);

	const renderTd = (value: number, i: number) => {
		return (
			<Styled.Td
				textAlign='center'
				onMouseEnter={() => {
					setSelectedValue(i), setDisplayRealValue(true);
				}}
				onMouseLeave={() => {
					setSelectedValue(null), setDisplayRealValue(false);
				}}
			>
				{displayRealValue && i === selectedValue ? value : formatNumber(value, true)}
			</Styled.Td>
		);
	};

	return (
		<React.Fragment>
			<Styled.Wrapper
				className={classNames(i % 2 ? 'dark' : 'light', { noData: noAssignments })}
				onClick={() => {
					noAssignments ? () => {} : setIsOpenDetails((prev) => !prev);
				}}
			>
				<Styled.Td>
					<Styled.Div display={'flex'} alignContent='center'>
						<Styled.IconWrapper className='clickable'>
							{!noAssignments && <Styled.CustomIcon className={classNames('detail-opener', { 'show-detail': isOpenDetails })} name={'chevron-down'} />}
						</Styled.IconWrapper>
						<Styled.CustomAvatar backgroundColor='white' imageUrl={influencer.links?.profileImage || ''} name={influencer.attributes.username ?? ''} />
						<div>
							<Styled.InfluencerName>{influencer.attributes.username}</Styled.InfluencerName>
							<Styled.InfluencerSubText>
								{influencer.attributes.followers && formatNumber(influencer.attributes.followers, true)} Followers
							</Styled.InfluencerSubText>
						</div>
					</Styled.Div>
				</Styled.Td>
				{renderTd(influencer.attributes.assignments, i)}
				{renderTd(influencer.attributes.clicks, i)}
				{renderTd(influencer.attributes.grossReach, i)}
				<Styled.Td textAlign='center'>
					{influencer.attributes.averageReachRate
						? formatNumber(influencer.attributes.averageReachRate, false, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
						: '-'}
				</Styled.Td>
				{renderTd(influencer.attributes.impressions, i)}
				{renderTd(influencer.attributes.engagement, i)}
				<Styled.Td textAlign='center'>
					{influencer.attributes.actualEngagementRate
						? formatNumber(influencer.attributes.actualEngagementRate, false, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
						: '-'}
				</Styled.Td>
			</Styled.Wrapper>

			{isOpenDetails ? (
				!assignmentDetails && !error ? (
					<Styled.AssignmentDetailsWrapper className={classNames(i % 2 ? 'dark' : 'light')}>
						<Styled.Td colSpan={100}>
							<LoadingSpinner position='center' />
						</Styled.Td>
					</Styled.AssignmentDetailsWrapper>
				) : (
					assignmentDetails &&
					assignmentDetails.data.map((assignmentDetail, j) => {
						const assignmentModel = assignmentDetailModels.find(assignmentDetail);
						return (
							<Styled.AssignmentDetailsWrapper key={assignmentDetail.id} className={classNames(i % 2 ? 'dark' : 'light')}>
								<Styled.Td className='assignment-name'>
									<Styled.AssignmentNameWrapper>
										{!isNil(assignmentDetail.links?.frontendContentManagement) ? (
											<Styled.LinkToContentManagement href={assignmentDetail.links?.frontendContentManagement} target='_blank' rel='noreferrer'>
												{assignmentDetail.attributes.name}
											</Styled.LinkToContentManagement>
										) : (
											assignmentDetail.attributes.name
										)}
										{!isNil(assignmentModel.instagramPost?.url) && (
											<a className='no-underline' href={assignmentModel.instagramPost.url} target='_blank' rel='noreferrer'>
												<Icon name='external-link' size='16' />
											</a>
										)}
									</Styled.AssignmentNameWrapper>
								</Styled.Td>
								<Styled.Td />
								<Styled.Td />
								{renderTd(assignmentDetail.attributes.reach, j)}
								<Styled.Td textAlign='center'>
									{assignmentDetail.attributes.reachRate ? formatNumber(assignmentDetail.attributes.reachRate * 100) + ' %' : '-'}
								</Styled.Td>
								{renderTd(assignmentDetail.attributes.impressions, j)}
								{renderTd(assignmentDetail.attributes.engagement, j)}
								<Styled.Td textAlign='center'>
									{assignmentDetail.attributes.actualEngagementRate ? formatNumber(assignmentDetail.attributes.actualEngagementRate * 100) + '%' : '-'}
								</Styled.Td>
							</Styled.AssignmentDetailsWrapper>
						);
					})
				)
			) : null}
		</React.Fragment>
	);
};

export default ByInfluencerTableItem;
