import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BrandModel } from 'api-models';
import { ListBrandsQuery } from 'api-queries';
import noResult from 'assets/icons/empty-state-no-result.svg';
import EmptyState from 'components/EmptyState/V2';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import BrandItemCard from 'components/Settings/Components/BrandItemCard/BrandItemCard';
import CenteredSpinner from 'components/Settings/Components/CenteredSpinner/CenteredSpinner';
import NotItems from 'components/Settings/Components/NoItems/NoItems';
import useInjection from 'hooks/useInjection';
import { pathSettingsClients } from 'routing/PathLookup';
import BrandManager from 'services/ApiManager/Brand.manager';
import Grid from 'styles/grid/grid';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import Styled from './BrandManagement.style';

const BrandManagement = () => {
	const [searchValue, setSearchValue] = useState('');
	const navigate = useNavigate();

	const manager = useInjection<BrandManager>(BrandManager);
	const queryBuilder = RequestQueryBuilder.create<ListBrandsQuery>(['logo']);
	const { result: brands, isLoading } = manager.listBrands(queryBuilder);

	const matchesSearch = (search: string, brand: BrandModel): boolean => {
		const toLower = search.toLowerCase().trim();
		const brandName = brand?.attributes?.name?.toLowerCase().trim() ?? '';

		if ('' === toLower) {
			return true;
		}

		return brandName.includes(toLower);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const filteredBrands = brands?.filter((brand) => matchesSearch(searchValue, brand));
	const sortedBrands = filteredBrands?.sort((a, b) => a.attributes.name.toLowerCase().localeCompare(b.attributes.name.toLowerCase()));

	return (
		<Grid.Container gap='24'>
			<Grid.Column xl={12}>
				<Styled.TopSection>
					<h4>Brand management</h4>
					<Styled.Text>
						View the brands that you are associated with. Each client can have any number of brands associated with it. You must add a brand before creating a
						campaign. <span>Please note, no information will be shared between brands.</span>
					</Styled.Text>
				</Styled.TopSection>
			</Grid.Column>
			<Grid.Column xl={12}>
				<Styled.FlexDiv>
					<Styled.Link onClick={() => navigate(pathSettingsClients())}>Go to clients to add new brand</Styled.Link>
					<Styled.SearchInputWrapper>
						<Field>
							<Input
								placeholder='Search for clients or brands'
								value={searchValue}
								onChange={({ target }) => setSearchValue(target.value)}
								contentBefore={<Icon name='search' size='16' />}
							/>
						</Field>
					</Styled.SearchInputWrapper>
				</Styled.FlexDiv>
			</Grid.Column>
			<Grid.Column xl={12}>
				{isLoading || !brands ? (
					<CenteredSpinner />
				) : brands.length > 0 ? (
					<Styled.BrandLogosWrapper
						className={classNames({ searching: searchValue.length > 0 && brands.filter((brand) => matchesSearch(searchValue, brand)).length > 0 })}
					>
						{sortedBrands?.map((brand) => {
							return <BrandItemCard key={brand.id} brand={brand} hide={!matchesSearch(searchValue, brand)} />;
						})}
						{filteredBrands?.length === 0 && (
							<Styled.EmptyResultImgWrapper>
								<EmptyState
									image={noResult}
									altText='No result found'
									testId='no-result-found'
									text={
										<>
											<p>
												Unfortunately, <strong>'{searchValue}'</strong> did not return any results.
											</p>
											<p>Check the spelling or add a new brand.</p>
										</>
									}
								/>
							</Styled.EmptyResultImgWrapper>
						)}
					</Styled.BrandLogosWrapper>
				) : (
					<NotItems icon='brand' title='Add brand' text='Visit the clients section in order to add a brand.' />
				)}
			</Grid.Column>
		</Grid.Container>
	);
};

export default BrandManagement;
