import { useEffect, useRef, useState, useMemo } from 'react';

import DetailSection from 'components/ContentManagement/Components/DetailSection';
import ShareContent from 'components/ContentManagement/Components/ShareContent/ShareContent';
import StatisticsForm from 'components/ContentManagement/Components/Views/Statistics/StatisticsForm';
import Summary from 'components/ContentManagement/Components/Views/Summary/Summary';
import IconButton from 'components/Discovery/Components/IconButton';
import { Heading } from 'components/Heading';
import ConfirmModal from 'components/Modals/ConfirmModal';
import Tabs from 'components/Tabs/Tabs';
import { CREATE_COMMENT } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { AssignmentReviewType, AssignmentStatus } from 'shared/Types/Assignment';
import { getLatestReview } from 'shared/utils/getLatestReview';
import colors from 'styles/variables/colors';

import Styled from './Assignment.style';
import AssignmentForm from './Components/AssignmentForm';
import { AssignmentProps, TabTypes } from './types';

const MAX_RETRY = 5; // Constant for retries

/**
 * Assignment
 * Component to show tabs, correct form, stats and summary
 * @param {AssignmentProps}
 * @returns {JSX.Element}
 */
const Assignment = ({
	CIOAssignment,
	selectedCampaign,
	selectedCIO,
	review,
	refresh,
	CIOArefresh,
	CIOAS,
	brandManagerUsers,
	campaignCode,
	repository,
}: AssignmentProps): JSX.Element => {
	const [activeTab, setActiveTab] = useState<TabTypes>(TabTypes.CONTENT);
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
	const [nextTab, setNextTab] = useState<TabTypes | null>(null);

	const user = useAppSelector((state) => state.user);

	const { assignment, attributes } = CIOAssignment;
	const { newFancyStatus: CampaignInstagramOwnerStatus } = attributes || {};

	const currentTab = useMemo(
		() =>
			({
				[CampaignInstagramOwnerStatus.REVIEW_ACCEPTED]: TabTypes.STATS,
				[CampaignInstagramOwnerStatus.STATS_UPLOADED]: TabTypes.SUMMARY,
			})[CampaignInstagramOwnerStatus] || TabTypes.CONTENT,
		[CampaignInstagramOwnerStatus],
	);

	useEffect(() => {
		setActiveTab(currentTab);
	}, [CIOAssignment.id, currentTab]);

	useEffect(() => {
		return () => {
			setActiveTab(TabTypes.CONTENT);
			setUnsavedChanges(false);
			setIsWarningModalOpen(false);
		};
	}, []);

	const isStatsDisabled =
		!selectedCIO?.attributes.joined ||
		CampaignInstagramOwnerStatus === AssignmentStatus.WAITING_FOR_UPLOAD ||
		CampaignInstagramOwnerStatus === AssignmentStatus.IN_REVIEW ||
		CampaignInstagramOwnerStatus === AssignmentStatus.DECLINED;

	const sectionRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		let isCancelled = false;
		if (!isCancelled) {
			sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
		return () => {
			isCancelled = true;
		};
	}, [CIOAssignment.id]);

	const latestReview = getLatestReview(CIOAssignment.reviews);

	const { userCan } = useFeaturePermissions({ ...review?.links });
	const CAN_COMMENT = userCan(CREATE_COMMENT);

	const isInfluencer = user.permissions?.isInfluencer ?? false;
	const summaryTab = isInfluencer ? TabTypes.SUMMARY_INFLUENCER : TabTypes.SUMMARY;

	const onClickComments = () => {
		let retryCount = 0;
		const scrollToInput = () => {
			if (retryCount > MAX_RETRY) return; // Prevent endless loop
			const commentInput = document.getElementById('comment-input');
			if (commentInput) {
				commentInput.scrollIntoView({ behavior: 'smooth', block: 'start' });
			} else {
				retryCount++;
				setTimeout(scrollToInput, 50);
			}
		};

		if (activeTab !== TabTypes.CONTENT) {
			setActiveTab(TabTypes.CONTENT);
			setTimeout(scrollToInput, 50);
		} else {
			scrollToInput();
		}
	};

	const handleTabChange = (tab: TabTypes) => {
		if (unsavedChanges) {
			setNextTab(tab);
			setIsWarningModalOpen(true);
		} else {
			setActiveTab(tab);
		}
	};

	const confirmTabChange = () => {
		setUnsavedChanges(false);
		setIsWarningModalOpen(false);
		if (nextTab) {
			setActiveTab(nextTab);
			setNextTab(null);
		}
	};

	return (
		<div ref={sectionRef}>
			<Styled.TitleWrapper>
				<Heading as='h4'>{CIOAssignment.assignment.attributes.name}</Heading>
				<Styled.IconWrapper>
					<ShareContent
						review={latestReview as unknown as AssignmentReviewType}
						refresh={refresh}
						CIOArefresh={CIOArefresh}
						brandManagerUsers={brandManagerUsers}
					/>
					{CAN_COMMENT && <IconButton helpText='Add comment' onClick={onClickComments} testId='cm-comments-icon' iconName='comment' />}
				</Styled.IconWrapper>
			</Styled.TitleWrapper>
			<Tabs
				selectedTab={activeTab}
				setSelectedTab={(tab: string) => handleTabChange(tab as TabTypes)}
				tabs={[
					{
						title: TabTypes.CONTENT,
						testId: 'cm-content-tab',
						key: 'content-tab',
					},
					{
						title: TabTypes.STATS,
						disabled: isStatsDisabled,
						testId: 'cm-stats-tab',
						key: 'stats-tab',
					},
					{
						title: summaryTab,
						testId: 'cm-summary-tab',
						key: 'summary-tab',
					},
					{
						title: TabTypes.INSTRUCTIONS,
						testId: 'cm-instructions-tab',
						key: 'instructions-tab',
					},
				]}
			/>

			{activeTab === TabTypes.INSTRUCTIONS && <DetailSection activeCampaign={selectedCampaign} activeAssignment={assignment} campaignCode={campaignCode} />}
			{activeTab === TabTypes.CONTENT && assignment && selectedCampaign && (
				<AssignmentForm
					hasJoined={selectedCIO?.attributes.joined ?? false}
					assignmentType={assignment.kind}
					selectedCampaign={selectedCampaign}
					CIOAssignment={CIOAssignment}
					CIOArefresh={CIOArefresh}
					campaignRefresh={refresh}
					goToStats={() => setActiveTab(TabTypes.STATS)}
				/>
			)}
			{activeTab === TabTypes.STATS && (
				<StatisticsForm
					contentType={assignment?.attributes.kind}
					CIOAssignment={{ id: CIOAssignment.id, name: CIOAssignment.assignment.attributes.name }}
					campaignStartDate={selectedCampaign.attributes.firstAssignmentGroupDate ?? ''}
					assignment={CIOAssignment}
					selectedCIO={selectedCIO}
					repository={repository}
					assignmentRefresh={CIOArefresh}
					getUnsavedChanges={(unsavedChanges) => setUnsavedChanges(unsavedChanges)}
					goToSummary={() => setActiveTab(TabTypes.SUMMARY)}
				/>
			)}
			{(activeTab === TabTypes.SUMMARY || activeTab === TabTypes.SUMMARY_INFLUENCER) && (
				<Summary selectedCIO={selectedCIO} CIOAS={CIOAS} refresh={CIOArefresh} repository={repository} hasJoined={selectedCIO?.attributes.joined ?? false} />
			)}
			<ConfirmModal
				IconBackgroundColor={colors.contentManagement.iconBackground}
				isModalOpen={isWarningModalOpen}
				toggleModal={() => setIsWarningModalOpen(!isWarningModalOpen)}
				action={confirmTabChange}
				title='Unsaved changes'
				isFetching={false}
				buttonText='Yes, leave'
				cancelButtonText='Cancel'
				icon='circle-cross'
			>
				<p>You have unsaved changes in the statistics form. They will get lost if you don’t save them.</p>
			</ConfirmModal>
		</div>
	);
};

export default Assignment;
