import classNames from 'classnames';
import { FormikErrors } from 'formik';
import moment from 'moment';
import { useEffect, useRef } from 'react';

import { BlueButton, TertiaryButton } from 'components/Button/Button';
import Icon from 'components/Icon';
import usePermissions from 'hooks/usePermissions';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { breakpoints } from 'styles/variables/media-queries';

import Styled from './Topbar.style';

const SubmitSection = ({
	errors,
	title,
	files,
	storyFrames,
	submitAction,
	isLoading,
	buttonText,
	buttonIcon,
	testId,
	buttonDisabled,
	className,
	isValid,
	onStatistics,
	statsCreatedAt,
	assignmentName,
	goToSummary,
	onClickEdit,
	onClickCancel,
	isEditing,
	assignmentApproved,
	displayCancelButton,
	setIsInEditMode,
}: {
	errors: FormikErrors<unknown> | undefined;
	title: string;
	files?: number | undefined;
	storyFrames?: number | undefined;
	submitAction: () => void;
	isLoading: boolean;
	buttonText: string;
	buttonIcon?: string;
	testId?: string;
	buttonDisabled?: boolean;
	className?: string;
	isValid?: boolean;
	onStatistics?: boolean;
	statsCreatedAt?: string;
	assignmentName?: string;
	goToSummary?: () => void;
	onClickEdit?: () => void;
	onClickCancel?: () => void;
	isEditing?: boolean;
	assignmentApproved: boolean;
	displayCancelButton?: boolean;
	setIsInEditMode?: (isInEditMode: boolean) => void;
}) => {
	const { width } = useWindowDimensions();
	const isMobileView = width <= +breakpoints.lg.split('px')[0];
	const { isInfluencer } = usePermissions();

	const firstErrorRef = useRef<HTMLDivElement | null>(null);

	const displayErrors = () => {
		if (!errors) return null;

		return Object.entries(errors).map(([key, value], i) => {
			const message = typeof value === 'string' ? value : value[0]?.text ?? 'Missing required fields';

			const handleErrorFocus = () => {
				const element = document.querySelector(`[name="${key}"]`) || document.querySelector('.error');
				element?.focus({ focusVisible: true });
				element?.scrollIntoView({ block: 'start', behavior: 'smooth' });
			};

			return (
				<Styled.ErrorMessage key={i} ref={i === 0 ? firstErrorRef : null} onClick={handleErrorFocus}>
					<Icon name='info-circle' size='12' />
					<span>{message}</span>
				</Styled.ErrorMessage>
			);
		});
	};

	useEffect(() => {
		if (firstErrorRef.current) {
			firstErrorRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
			firstErrorRef.current.focus();
		}
	}, [errors]);

	const renderFileInfo = () => {
		if (errors && Object.entries(errors).length > 0) return displayErrors();
		if (files) return `${files} file${files > 1 ? 's' : ''} will be submitted`;
		if (storyFrames) return `${storyFrames} frame${storyFrames > 1 ? 's' : ''} selected`;
		return '';
	};

	const createdAt = moment(statsCreatedAt);
	const daysLeft = 15 - moment().diff(createdAt, 'days');

	return isMobileView ? (
		<Styled.SubmitSection data-testid={testId}>
			<Styled.SubmitSectionContent>
				<div>
					<Styled.AssignmentName>{title}</Styled.AssignmentName>
					<Styled.FileText>{renderFileInfo()}</Styled.FileText>
				</div>
				{displayCancelButton && (
					<TertiaryButton onClick={() => setIsInEditMode && setIsInEditMode(false)} data-testid='cm-edit'>
						Cancel
					</TertiaryButton>
				)}
				{!onStatistics && (
					<TertiaryButton onClick={isEditing ? onClickCancel : onClickEdit} data-testid='cm-edit'>
						{isEditing ? 'Cancel' : 'Edit'}
					</TertiaryButton>
				)}
				<BlueButton
					isLoading={isLoading}
					type='submit'
					data-testid='submit-button'
					onClick={submitAction}
					disabled={buttonDisabled}
					aria-disabled={buttonDisabled}
					aria-label={buttonDisabled ? 'Submit button disabled due to form errors or missing data' : 'Submit'}
					className={classNames({ turnBlue: isValid }, className)}
				>
					{buttonText}
				</BlueButton>
			</Styled.SubmitSectionContent>
		</Styled.SubmitSection>
	) : (
		<Styled.DFlexColumn className={onStatistics ? 'onStats' : ''} data-testid={testId}>
			<Styled.AssignmentName className={onStatistics ? 'onStats' : ''}>{assignmentName}</Styled.AssignmentName>
			<div style={{ display: 'flex', gap: '8px', width: '100%', justifyContent: 'end' }}>
				{displayCancelButton && (
					<TertiaryButton onClick={() => setIsInEditMode && setIsInEditMode(false)} data-testid='cm-edit'>
						Cancel
					</TertiaryButton>
				)}
				<BlueButton
					isLoading={isLoading}
					icon={buttonIcon}
					onClick={submitAction}
					data-testid='submit-button'
					type='submit'
					disabled={buttonDisabled}
					aria-disabled={buttonDisabled}
					aria-label={buttonDisabled ? 'Submit button disabled due to form errors or missing data' : 'Submit'}
				>
					{buttonText}
				</BlueButton>
			</div>
			{!isInfluencer() && onStatistics && !assignmentApproved && (
				<Styled.AutoApproveText>
					<Styled.SpanLink onClick={() => goToSummary && goToSummary()}>Click here to approve this assignment for payment now. </Styled.SpanLink>
					{`If no action is taken, this assignment will be auto-approved ${daysLeft} days after stats are added unless declined.`}
				</Styled.AutoApproveText>
			)}
		</Styled.DFlexColumn>
	);
};

export default SubmitSection;
