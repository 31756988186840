import styled from 'styled-components';

import { TertiaryButton } from 'components/Button';
import colors from 'styles/variables/colors';

const Wrapper = styled.div`
	position: relative;
`;

const OptionsWrapper = styled.div`
	position: sticky;
	display: flex;
	column-gap: 8px;
	row-gap: 8px;
	margin-top: 40px;
	padding-bottom: 1.5rem;

	flex-wrap: wrap;

	max-height: 35vh;
	overflow-y: auto;
`;

const Option = styled.div`
	height: 40px;
	border-radius: 10px;
	border: 1px solid ${colors.onboarding.option.border};
	padding: 8px 16px;
	cursor: pointer;

	display: flex;
	align-items: center;
	column-gap: 8px;

	&.selected {
		background-color: ${colors.onboarding.option.selected.background};
		color: ${colors.onboarding.option.selected.color};

		.checkbox {
			background-color: transparent;
			border-color: ${colors.onboarding.option.selected.color};

			overflow: hidden;
		}
	}
`;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${colors.onboarding.divider};
	margin-bottom: 24px;
`;

const Button = styled(TertiaryButton)`
	display: flex;
	width: 100%;
	height: 44px;

	font-size: 1rem;
	flex: 1;
	margin-bottom: 16px;

	&.finish-button {
		&:not(:disabled) {
			border-color: ${colors.onboarding.button.hover.background};
			background-color: ${colors.onboarding.button.hover.background};
			color: ${colors.onboarding.button.hover.color};
		}
	}
`;

const Styled = {
	Wrapper,
	OptionsWrapper,
	Option,
	Divider,
	Button,
};

export default Styled;
