import { AxiosError } from 'axios';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BrandInviteModel, BrandModel, BrandPrivilegeModel, ClientModel, UserModel } from 'api-models';
import { ListBrandsQuery } from 'api-queries';
import CenteredSpinner from 'components/Settings/Components/CenteredSpinner/CenteredSpinner';
import DangerZone from 'components/Settings/Components/DangerZone/DangerZone';
import EditBrandName from 'components/Settings/Components/EditBrandName/EditBrandName';
import EditUrl from 'components/Settings/Components/EditUrl/EditUrl';
import InfoText from 'components/Settings/Components/InfoText/InfoText';
import InvitationList from 'components/Settings/Components/InvitationList/InvitationList';
import InviteUsers from 'components/Settings/Components/InviteUsers/InviteUsers';
import LogoAndAdmins from 'components/Settings/Components/LogoAndAdmins/LogoAndAdmins';
import SubTitle from 'components/Settings/Components/SubTitle/SubTitle';
import UserListCard from 'components/Settings/Components/UserListCard/UserListCard';
import UsersWithRolesList from 'components/Settings/Components/UsersWithRolesList/UsersWithRolesList';
import { OrganizationHierarchyRole } from 'components/Settings/types';
import { DELETE, EDIT, CREATE_INVITATION } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { pathSettingsClientShow } from 'routing/PathLookup';
import BrandManager from 'services/ApiManager/Brand.manager';
import toast from 'services/Toast';
import Grid from 'styles/grid/grid';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import Styled from './BrandDetails.style';

const BrandDetails = () => {
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const manager = useInjection<BrandManager>(BrandManager);
	const { brandId } = useParams<{ brandId: string }>();

	const queryBuilder = RequestQueryBuilder.create<ListBrandsQuery>(['logo', 'edit', 'delete', 'createInvitation', 'deleteLogo']).withInclude('client');
	const { repository: BrandsRespository, isLoading: isLoadingBrands, mutate: mutateFn } = manager.listBrands(queryBuilder);
	const brand = brandId ? BrandsRespository.find<BrandModel>('brand', brandId) ?? undefined : undefined;

	const { repository, mutate } = manager.listUsersAndInvitations(brandId!);

	const invites: BrandInviteModel[] = repository.findAll<BrandInviteModel>('invite') ?? [];
	const userPrivileges: BrandPrivilegeModel[] = repository.findAll<BrandPrivilegeModel>('brandPrivilege') ?? [];

	const { userCan } = useFeaturePermissions(brand?.links);
	const userCanCreateInvitation = userCan(CREATE_INVITATION);
	const userCanDelete = userCan(DELETE);
	const userCanEdit = userCan(EDIT);

	function getUserFromBrandPrivilege(privilege: BrandPrivilegeModel): UserModel {
		return repository.findOneByRelation<UserModel, BrandPrivilegeModel>(privilege, 'user') as UserModel;
	}

	function getClient(brand: BrandModel): ClientModel {
		return repository.findOneByRelation<ClientModel, BrandModel>(brand, 'client') as ClientModel;
	}

	const admins = useMemo(
		() => userPrivileges.filter((privilege) => privilege.attributes.role === OrganizationHierarchyRole.ADMINISTRATOR).map(getUserFromBrandPrivilege),
		[userPrivileges],
	);

	const inviteFn = async (emails: string[], role: OrganizationHierarchyRole) => {
		if (!brand) return;
		try {
			await manager.inviteUsers(brand.id, { role, emails });
			mutate();
		} catch (e) {
			console.error(e);
			toast.error(getErrorMessageOnPost('sending invitations'));
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const changeUserRole = async (target: EventTarget & HTMLSelectElement, user: UserModel) => {
		target.disabled = true;
		try {
			setIsLoading(true);
			if (target.value === 'delete') {
				await manager.deleteUser(brand!.id, user.id);
				toast.success('User access revoked');
			} else {
				await manager.updateRole(brand!.id, user.id, { role: target.value });
				toast.success('Role updated');
			}
			mutate();
		} catch (e) {
			console.error(e);
			toast.error(getErrorMessageOnPost('changing the role'));
		} finally {
			setIsLoading(false);
			target.disabled = false;
		}
	};

	const deleteInvite = (inviteId: string) => {
		manager
			.deleteInvite(brand!.id, inviteId)
			.then(() => {
				toast.success('Invite deleted');
				mutate();
			})
			.catch((e: AxiosError) => {
				console.error(e);
				toast.error(getErrorMessageOnPost('deleting the invite'));
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const deleteBrand = async () => {
		if (!brand) return;
		setIsLoading(true);
		try {
			await manager.delete(brand.id);
			const client = getClient(brand);
			if (client) navigate(pathSettingsClientShow(client.id));
			mutateFn();
		} catch (e) {
			console.error(e);
			toast.error('Failed to delete brand');
		} finally {
			setIsLoading(false);
		}
	};

	const deleteImage = () => {
		brand &&
			manager.deleteLogo(brand.id).then(() => {
				mutateFn();
				toast.success('Logo deleted');
			});
	};

	const inviteSection = (top?: boolean) => {
		return (
			userCanCreateInvitation && (
				<InviteUsers
					availableRoles={(brand?.attributes.availableRoles as OrganizationHierarchyRole[]) ?? []}
					top={top} // SecondaryButton on top
					roles={userPrivileges}
					invites={invites}
					deleteInvite={(inviteId: string) => deleteInvite(inviteId)}
					getUser={getUserFromBrandPrivilege}
					changeUserRole={changeUserRole}
					companyName={brand?.attributes?.name ?? ''}
					inviteTitle={`Invite user to ${brand?.attributes?.name}`}
					buttonText='Invite'
					drawerTitle={`Brand management`}
					testId='add-brand-users'
					inviteFn={(emails, role) => inviteFn(emails, role)}
				/>
			)
		);
	};

	return isLoadingBrands || brand == null ? (
		<CenteredSpinner />
	) : (
		<>
			<Styled.ButtonsWrapper>
				<EditBrandName brand={brand} client={getClient(brand)} canEdit={userCanEdit} />
				{userCanDelete && (
					<DangerZone
						infoText={`Be aware, you can't regret deleting the brand. Please, be sure.`}
						deleteButtonText='Delete Brand'
						deleteModalTitle={`Are you sure that you want to delete brand ${brand.attributes?.name ?? ''}?`}
						action={() => deleteBrand()}
						isLoading={isLoading}
					/>
				)}
				{inviteSection(true)}
			</Styled.ButtonsWrapper>
			<Grid.Container gap='32'>
				<Grid.Column xl={12}>
					<Styled.Card>
						<LogoAndAdmins
							admins={admins}
							deleteImageFn={deleteImage}
							uploadFn={(id: string, file: File) => manager.uploadLogo(id, file)}
							item={brand}
							canEdit={userCanEdit}
						/>
					</Styled.Card>
				</Grid.Column>
				<Grid.Column xl={12}>
					<Styled.Card>
						<EditUrl item={brand} updateUrl={(url: string) => manager.update(brand.id, { url })} canEdit={userCanEdit} />
					</Styled.Card>
				</Grid.Column>
				<Grid.Column xl={12}>
					<SubTitle text={`Manage access to ${brand.attributes.name}`} />
					<InfoText text={userCanCreateInvitation ? `Add or manage who has access to all of ${brand.attributes.name}'s campaigns.` : ''} />
					<UserListCard
						title='People with access'
						text={
							<>
								{userCanCreateInvitation ? (
									<p>
										Share access to {brand.attributes?.name} by inviting individuals. People with access can view and be assigned to all of{' '}
										{brand.attributes?.name}'s campaigns. Permissions can be edited at any time.
									</p>
								) : (
									<p>People with access can view and be assigned to all of {brand.attributes?.name}'s campaigns.</p>
								)}
							</>
						}
					>
						<>
							{inviteSection()}
							<Styled.ListWrapper>
								<UsersWithRolesList
									availableRoles={(brand?.attributes.availableRoles as OrganizationHierarchyRole[]) ?? []}
									roles={userPrivileges}
									changeUserRole={changeUserRole}
									getUser={getUserFromBrandPrivilege}
									canEdit={userCanCreateInvitation}
								/>
								<InvitationList invites={invites} deleteInvite={deleteInvite} canEdit={userCanCreateInvitation} />
							</Styled.ListWrapper>
						</>
					</UserListCard>
				</Grid.Column>
			</Grid.Container>
		</>
	);
};

export default BrandDetails;
