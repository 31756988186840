/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store } from 'json-api-models';
import { orderBy } from 'lodash';
import { useState, useEffect } from 'react';

import { selectedTabType } from 'components/ContentManagement/Components/Comments/types';
import { AssignmentReviewCommentType, AssignmentReviewInternalCommentType } from 'components/ContentManagement/types';
import { getErrorMessageOnFetch, getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import toast from 'services/Toast';

interface UseCommentsHandlerParams {
	reviewLinks: {
		comments: string;
		internalComments: string;
	};
	getComments: (url: string) => Promise<any>;
	postComment: (url: string, text: string) => Promise<any>;
	canComment: boolean;
	canCommentInternal: boolean;
	COMMENTS_INTERVAL_COUNT?: number;
	id: string;
	isInfluencer: boolean;
}

interface UseCommentsHandlerResult {
	comments: Array<AssignmentReviewCommentType | AssignmentReviewInternalCommentType>;
	internalComments: Array<AssignmentReviewCommentType | AssignmentReviewInternalCommentType>;
	postCommentHandler: (text: string, activeTab: selectedTabType.Influencer | selectedTabType.BrandManager) => Promise<void>;
	activeCommentTab: selectedTabType.Influencer | selectedTabType.BrandManager;
	setActiveCommentTab: (tab: selectedTabType.Influencer | selectedTabType.BrandManager) => void;
}

const DEFAULT_COMMENTS_INTERVAL_COUNT = 10000;

export const useCommentsHandler = ({
	id,
	reviewLinks,
	getComments,
	postComment,
	canComment,
	canCommentInternal,
	COMMENTS_INTERVAL_COUNT = DEFAULT_COMMENTS_INTERVAL_COUNT,
	isInfluencer,
}: UseCommentsHandlerParams): UseCommentsHandlerResult => {
	const [comments, setComments] = useState<Array<AssignmentReviewCommentType | AssignmentReviewInternalCommentType>>([]);
	const [internalComments, setInternalComments] = useState<Array<AssignmentReviewCommentType | AssignmentReviewInternalCommentType>>([]);
	const [activeCommentTab, setActiveCommentTab] = useState<selectedTabType.Influencer | selectedTabType.BrandManager>(
		canCommentInternal ? selectedTabType.BrandManager : selectedTabType.Influencer,
	);

	let commentsInterval: NodeJS.Timeout;
	let internalCommentsInterval: NodeJS.Timeout;

	const fetchInfluencerComments = async () => {
		try {
			const response = await getComments(reviewLinks.comments);
			const data = response.data;
			const model = new Store();
			model.sync(data);
			const sortedComments = orderBy(model.findAll('assignmentReviewComment') as AssignmentReviewCommentType[], 'createdAt');
			setComments(sortedComments);
		} catch (e) {
			toast.error(getErrorMessageOnFetch('Influencer comments'));
			console.error(e);
		}
	};

	const fetchInternalComments = async () => {
		try {
			const response = await getComments(reviewLinks.internalComments);
			const data = response.data;
			const model = new Store();
			model.sync(data);
			const sortedComments = orderBy(model.findAll('assignmentReviewInternalComment') as AssignmentReviewInternalCommentType[], 'createdAt');
			setInternalComments(sortedComments);
		} catch (e) {
			toast.error(getErrorMessageOnFetch('Brand manager comments'));
			console.error(e);
		}
	};

	const pollInfluencerComments = () => {
		if (canComment) {
			fetchInfluencerComments();
			commentsInterval = setInterval(fetchInfluencerComments, COMMENTS_INTERVAL_COUNT);
		}
	};

	const pollInternalComments = () => {
		if (canCommentInternal && !isInfluencer) {
			fetchInternalComments();
			internalCommentsInterval = setInterval(fetchInternalComments, COMMENTS_INTERVAL_COUNT);
		}
	};

	const postCommentHandler = async (text: string, activeTab: selectedTabType.Influencer | selectedTabType.BrandManager) => {
		const postUrl = activeTab === selectedTabType.BrandManager ? reviewLinks.internalComments : reviewLinks.comments;

		try {
			await postComment(postUrl, text);
			await Promise.all([fetchInfluencerComments(), !isInfluencer ? fetchInternalComments() : Promise.resolve()]);
			// CIOArefresh use this?
			toast.success('Comment posted successfully');
		} catch (e) {
			toast.error(getErrorMessageOnPost('posting a comment'));
			console.error(e);
		}
	};

	useEffect(() => {
		pollInfluencerComments();
		pollInternalComments();

		return () => {
			clearInterval(commentsInterval);
			clearInterval(internalCommentsInterval);
		};
	}, [reviewLinks.comments, reviewLinks.internalComments, id]);

	return {
		comments,
		internalComments,
		postCommentHandler,
		activeCommentTab,
		setActiveCommentTab,
	};
};
