import { Formik, FormikProps, useFormikContext } from 'formik';
import { Model, Store } from 'json-api-models';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import SubmitSection from 'components/ContentManagement/Components/Topbar/SubmitSection';
import AddContentUrl from 'components/ContentManagement/Components/Views/Statistics/Components/AddContentUrl/AddContentUrl';
import AddScreenShots from 'components/ContentManagement/Components/Views/Statistics/Components/AddScreenShots/AddScreenShots';
import InfluencerConnected from 'components/ContentManagement/Components/Views/Statistics/Components/InfluencerConnected/InfluencerConnected';
import InfluencerNotConnected from 'components/ContentManagement/Components/Views/Statistics/Components/InfluencerNotConnected/InfluencerNotConnected';
import ListScreenshots from 'components/ContentManagement/Components/Views/Statistics/Components/ListScreenshots/ListScreenshots';
import SaveStatisticsModal from 'components/ContentManagement/Components/Views/Statistics/Components/SaveStatisticsModal/SaveStatisticsModal';
import Screenshots from 'components/ContentManagement/Components/Views/Statistics/Components/Screenshots';
import SelectDate from 'components/ContentManagement/Components/Views/Statistics/Components/SelectDate/SelectDate';
import StatisticFields from 'components/ContentManagement/Components/Views/Statistics/Components/StatisticFields/StatisticFields';
import StatisticsOverview from 'components/ContentManagement/Components/Views/Statistics/Components/StatisticsOverview/StatisticsOverview';
import StoryFrameList from 'components/ContentManagement/Components/Views/Statistics/Components/StoryFrameList/StoryFrameList';
import useContentManagementData, { StatisticsPayloadForSelectorType } from 'components/ContentManagement/hooks';
import { EDIT, CREATE_INSTAGRAM_POST, CREATE_INSTAGRAM_STORY, CREATE_INSTAGRAM_REEL, CREATE_TIKTOK } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getErrorMessageOnFetch, getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import toast from 'services/Toast';
import { AssignmentStatus } from 'shared/Types/Assignment';
import { AssignmentType } from 'shared/helpers/Assigment/types';
import IRepository from 'utils/Repository/IRepository';

import Styled from './Statistics.style';
import { validateStatsForm } from './Validations';
import { StatisticsFormValues, defaultValues, getUploadStatisticsLink, mapFormValues } from './helpers';
import { useInfluencer } from './hooks/useInfluencer';
import useInstagramStories from './hooks/useInstagramStories';

/**
 * Statistics
 * Component to hold the stats form
 * @param {StatisticsProps} props
 * @returns {JSX.Element}
 */
const StatisticsForm = ({
	campaignStartDate,
	selectedCIO,
	CIOAssignment,
	assignment,
	assignmentRefresh,
	getUnsavedChanges,
	goToSummary,
	repository,
	contentType,
}: {
	campaignStartDate: string;
	selectedCIO: Model;
	CIOAssignment: { id: string; name: string };
	assignment: Model;
	assignmentRefresh: () => Promise<void>;
	getUnsavedChanges: (unsavedChanges: boolean) => void;
	goToSummary: () => void;
	repository: IRepository;
	contentType: AssignmentType;
}): JSX.Element => {
	const [files, setFiles] = useState<File[]>([]);
	const [isInEditMode, setIsInEditMode] = useState<boolean>(false);

	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [unsavedChanges, setUnsavedChanges] = useState(false);

	const [selectedDate, setSelectedDate] = useState<[Date, Date]>();
	const [urlAdded, setUrlAdded] = useState(false);

	const [formValues, setFormValues] = useState<StatisticsFormValues>(defaultValues);
	const [LastInsightDataUpdateAt, setLastInsightDataUpdateAt] = useState<string>();
	const [isManagedByApi, setIsManagedByApi] = useState<boolean>(false);

	const IS_STORY = contentType === AssignmentType.INSTAGRAM_STORY;
	const IS_TIKTOK = contentType === AssignmentType.TIKTOK_VIDEO;
	const ASSIGNMENT_APPROVED = assignment.newFancyStatus === AssignmentStatus.APPROVED;

	const instagramPosts = assignment.instagramPosts && assignment.instagramPosts[assignment.instagramPosts?.length - 1];
	const instagramStories = assignment.instagramStories && assignment.instagramStories[assignment.instagramStories?.length - 1];

	const statsEntity: Model | undefined = instagramPosts || instagramStories;
	const links = statsEntity ? { ...assignment.links, ...statsEntity.links } : { ...assignment.links };
	const { userCan } = useFeaturePermissions(links);

	const { postStatistics, patchEditStatistics, uploadStatisticsScreenshot, getStories, patchStorySelectorStatistics } = useContentManagementData();

	const MEDIA_UPLOAD_URL: string | undefined = links.uploadFile ?? links.uploadStatisticsScreenshot;

	const { isInfluencer, INFLUENCER_PROFILE_CONNECTED, FETCH_STORIES_URL } = useInfluencer({
		selectedCIO: selectedCIO,
		repository: repository,
		assignmentNetwork: assignment.assignment.attributes.network,
	});

	const {
		userStoryFrames,
		isUserStoriesLoading,
		noResult,
		framesConnectedToOtherAssignments,
		selectedStoryItems,
		setSelectedStoryItems,
		fetchedScreenShots,
		setFetchedScreenShots,
	} = useInstagramStories({
		FETCH_STORIES_URL,
		selectedDate,
		INFLUENCER_PROFILE_CONNECTED,
		CIOAssignment: { id: CIOAssignment.id, name: CIOAssignment.name },
		statsEntity,
		formValues,
		setFormValues,
		getStories,
	});

	// eslint-disable-next-line unused-imports/no-unused-vars
	const CAN_CREATE_STATS = userCan(CREATE_INSTAGRAM_POST) || userCan(CREATE_INSTAGRAM_STORY) || userCan(CREATE_INSTAGRAM_REEL) || userCan(CREATE_TIKTOK);
	const CAN_EDIT_STATS = userCan(EDIT);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const unloadCallback = (event: any) => {
			event.preventDefault();
			event.returnValue = '';
			return '';
		};

		window.addEventListener('beforeunload', unloadCallback);

		return () => {
			window.removeEventListener('beforeunload', unloadCallback);
		};
	}, []);

	const useStorySelector = INFLUENCER_PROFILE_CONNECTED && IS_STORY;

	useEffect(() => {
		if (useStorySelector) {
			setUrlAdded(true);
		}
	}, [useStorySelector]);

	const renderApprovedMessage = () => {
		return isInfluencer && !statsEntity && ASSIGNMENT_APPROVED;
	};

	const handleMediaUpload = async () => {
		if (files && files.length > 0 && MEDIA_UPLOAD_URL) {
			await postMedia(MEDIA_UPLOAD_URL, files);
		} else {
			handleSuccess();
		}
	};

	const handlePostSuccess = () => {
		assignmentRefresh().then(() => {
			toast.success('Statistics saved successfully');
			setFiles([]);
		});
	};

	const handlePostError = (error: Error) => {
		toast.error(getErrorMessageOnPost('saving the statistics'));
		console.error(error);
	};

	const handlePostMediaUpload = async (url: string) => {
		if (files && files.length > 0 && url) {
			await postMedia(url, files);
		} else {
			handlePostSuccess();
		}
	};

	const handleSuccess = () => {
		assignmentRefresh().finally(() => {
			toast.success('The statistics updated successfully');
			setIsInEditMode(false);
			setIsSaveLoading(false);
			setIsSaveModalOpen(false);
		});
	};

	const handleError = (error: Error) => {
		toast.error(getErrorMessageOnPost('updating the statistics'));
		console.error(error);
		setIsInEditMode(false);
		setIsSaveLoading(false);
		setIsSaveModalOpen(false);
	};

	const patchStats = async () => {
		setIsSaveLoading(true);
		if (IS_STORY) {
			formValues.url = '';
		}

		const formData = mapFormValues(formValues, useStorySelector ? selectedStoryItems : [], useStorySelector);

		try {
			if (useStorySelector) {
				const createInstagramStoryEndpoint = assignment.links.edit;
				await patchStorySelectorStatistics(createInstagramStoryEndpoint, formData as StatisticsPayloadForSelectorType);
			} else {
				await patchEditStatistics(links[EDIT], formData as StatisticsFormValues, contentType);
			}
			await handleMediaUpload();
		} catch (error) {
			handleError(error as Error);
		}
	};

	const postStats = async () => {
		setIsSaveLoading(true);

		try {
			const data = await postStatistics(getUploadStatisticsLink(userCan, links), formValues, contentType);
			const model = new Store();
			model.sync(data.data);
			const obj = model.findAll(IS_STORY ? 'instagramStory' : 'instagramPost')[0];
			const url = IS_STORY ? obj.links.uploadFile : obj.links.uploadStatisticsScreenshot;

			await handlePostMediaUpload(url);
		} catch (error) {
			handlePostError(error as Error);
		} finally {
			setIsSaveLoading(false);
			setIsInEditMode(false);
			setIsSaveModalOpen(false);
		}
	};

	const postMedia = (url: string, medias: Array<File>) => {
		return Promise.all(
			medias.map((media) => {
				uploadStatisticsScreenshot(url, media);
			}),
		)
			.then(() => {
				assignmentRefresh().then(() => {
					toast.success('Statistics saved successfully');
					setFiles([]);
				});
			})
			.catch((e) => {
				toast.error(getErrorMessageOnFetch('Statistics'));
				console.error(e);
			})
			.finally(() => {
				setIsSaveLoading(false);
				setIsInEditMode(false);
				setIsSaveModalOpen(false);
			});
	};

	useEffect(() => {
		// Set form values if assignment has stats uploaded.
		if (statsEntity) {
			setFormValues({
				postedAt: moment(statsEntity.attributes.postedAt).format('yyyy-MM-DD') || '',
				impressions: statsEntity.attributes.impressions || 0,
				reach: statsEntity.attributes.reach || 0,
				comments: statsEntity.attributes.commentsCount || 0,
				likes: statsEntity.attributes.likeCount || 0,
				saves: statsEntity.attributes.saves || 0,
				url: statsEntity.attributes.url || statsEntity.attributes.generatedUrl || '',
				shares: statsEntity.attributes.shares || 0,
				reachLastFrame: statsEntity.attributes.reachLastFrame || 0,
				stickerLinkClicks: statsEntity.attributes.stickerLinkClicks || 0,
				stickerTaps: statsEntity.attributes.stickerTaps || 0,
				otherInteractions: statsEntity.attributes.otherInteractions || 0,
				screenshot: statsEntity.links.statisticsScreenshot || '', // Note: This is just a check for the validation, this url is not used
			});
			const dateRange = [new Date(statsEntity.attributes.postedAt), new Date(statsEntity.attributes.postedAt)];
			setSelectedDate(dateRange);
			setLastInsightDataUpdateAt(statsEntity.attributes.insightDataUpdatedAt);
			setIsManagedByApi(statsEntity.attributes.managedByApi);
			if (statsEntity.attributes.url?.length > 0 || statsEntity.attributes.generatedUrl?.length > 0) setUrlAdded(true); // Jon fix short code to url?

			if (!IS_STORY) {
				setFetchedScreenShots(assignment.instagramPosts[0].screenshots);
			}
		} else {
			setFormValues({
				postedAt: '',
				impressions: 0,
				reach: 0,
				comments: 0,
				likes: 0,
				saves: 0,
				url: '',
				shares: 0,
				reachLastFrame: 0,
				stickerLinkClicks: 0,
				stickerTaps: 0,
				otherInteractions: 0,
				screenshot: '',
			});
		}
	}, [assignment]);

	const formRef = useRef<FormikProps<StatisticsFormValues>>(null);
	const FormObserver: React.FC = () => {
		const { dirty } = useFormikContext();

		useEffect(() => {
			if (dirty) {
				setUnsavedChanges(true);
			} else {
				setUnsavedChanges(false);
			}
		}, [dirty]);

		return null;
	};

	useEffect(() => {
		getUnsavedChanges(unsavedChanges);
	}, [unsavedChanges]);

	return (
		<Styled.Wrapper data-testid='statistics-form'>
			<Formik
				innerRef={formRef}
				enableReinitialize
				initialValues={formValues}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={validateStatsForm(contentType, false, false)}
				onSubmit={(values) => {
					setFormValues(values);
					setIsSaveModalOpen(true);
				}}
			>
				{({ handleSubmit, setFieldValue, errors, setErrors, values }) => {
					return (
						<>
							<FormObserver />
							{INFLUENCER_PROFILE_CONNECTED ? <InfluencerConnected /> : !IS_TIKTOK && isInfluencer && !renderApprovedMessage() && <InfluencerNotConnected />}
							{(CAN_CREATE_STATS && !statsEntity) || (CAN_EDIT_STATS && isInEditMode) ? (
								<Styled.Form>
									{statsEntity && !isInEditMode && (
										<StatisticsOverview
											setIsInEditMode={setIsInEditMode}
											isInEditMode={isInEditMode}
											LastInsightDataUpdateAt={LastInsightDataUpdateAt}
											values={values}
											contentType={contentType}
											isManagedByApi={isManagedByApi}
										/>
									)}
									<SelectDate
										useStorySelector={useStorySelector}
										campaignStartDate={campaignStartDate || ''}
										selectedDate={selectedDate}
										selectedPostingDate={selectedDate && selectedDate[0]}
										profileConnected={INFLUENCER_PROFILE_CONNECTED}
										setSelectedDate={setSelectedDate}
										setFieldValue={setFieldValue}
									/>
									{selectedDate && !useStorySelector && (
										<AddContentUrl
											instagramLinkCheck={assignment.influencer.links.testInstagramPostInsightAccess}
											setUrlAdded={setUrlAdded}
											profileConnected={INFLUENCER_PROFILE_CONNECTED}
											contentType={contentType}
											values={values}
											onInput={() => {
												const { url: _, ...err } = errors;
												setErrors(err);
											}}
										/>
									)}
									{useStorySelector && (
										<StoryFrameList
											noResult={noResult}
											assignmentApproved={ASSIGNMENT_APPROVED}
											frames={userStoryFrames}
											framesConnectedToOtherAssignments={framesConnectedToOtherAssignments}
											selectedStoryItems={selectedStoryItems}
											setSelectedStoryItems={setSelectedStoryItems}
											isLoading={isUserStoriesLoading}
											setFormValues={setFormValues}
											values={values}
										/>
									)}
									{selectedDate && urlAdded && (
										<Screenshots onFrameSelector={useStorySelector} isInfluencer={isInfluencer} contentType={contentType}>
											<>
												<ListScreenshots assignmentApproved={ASSIGNMENT_APPROVED} refresh={assignmentRefresh} fetchedScreenShots={fetchedScreenShots} />
												<AddScreenShots
													url={
														IS_STORY
															? assignment?.instagramStories[0]?.links.uploadFile // NEED TO CHANGES THIS
															: assignment?.instagramPosts[0]?.links.uploadStatisticsScreenshot
													}
													setFiles={setFiles}
													files={files}
													refresh={assignmentRefresh}
													setFieldValue={setFieldValue}
												/>
											</>
										</Screenshots>
									)}
									{selectedDate && <StatisticFields contentType={contentType} />}
									<SubmitSection
										onStatistics
										statsCreatedAt={statsEntity?.attributes.createdAt}
										assignmentName={CIOAssignment.name}
										files={files?.length}
										storyFrames={selectedStoryItems?.length}
										submitAction={handleSubmit}
										isLoading={isSaveLoading}
										errors={errors}
										buttonText={useStorySelector ? 'Confirm' : 'Save'}
										testId={isInEditMode ? 'save-edit' : 'cm-stats-save'}
										className='fit-content'
										title={useStorySelector ? 'Select story' : 'Save'}
										goToSummary={goToSummary}
										assignmentApproved={ASSIGNMENT_APPROVED}
										displayCancelButton={isInEditMode}
										setIsInEditMode={setIsInEditMode}
									/>
								</Styled.Form>
							) : (
								<StatisticsOverview
									setIsInEditMode={setIsInEditMode}
									isInEditMode={isInEditMode}
									LastInsightDataUpdateAt={LastInsightDataUpdateAt}
									values={values}
									contentType={contentType}
									isManagedByApi={isManagedByApi}
								/>
							)}
						</>
					);
				}}
			</Formik>
			<SaveStatisticsModal
				isManagedByApi={isManagedByApi}
				isInEditMode={isInEditMode}
				statsEntity={statsEntity !== undefined}
				isOpen={isSaveModalOpen}
				setIsModalOpen={setIsSaveModalOpen}
				isLoading={isSaveLoading}
				onClickSave={statsEntity || (IS_STORY && INFLUENCER_PROFILE_CONNECTED) ? patchStats : postStats}
			/>
		</Styled.Wrapper>
	);
};

export default StatisticsForm;
