import classNames from 'classnames';
import { first, isNil } from 'lodash';
import React, { forwardRef, useImperativeHandle } from 'react';
import { generatePath } from 'react-router';
import { Link, useLocation, useParams } from 'react-router-dom';

import { CampaignInstagramOwnerStatusText, getStatus, ManagerCampaignInstagramOwnerStatusPriority } from 'components/ContentManagement/Utils';
import { Heading } from 'components/Heading';
import useInjection from 'hooks/useInjection';
import GenericManager from 'services/ApiManager/Generic.manager';
import { CampaignInstagramOwnerAssignment, Review, Assignment } from 'shared/Types/Assignment';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import AssignmentItem from './AssignmentItem';
import Styled from './AssignmentList.style';
import { AssignmentListProps } from './types';

// Skeleton loader component
const SkeletonLoader = () => (
	<Styled.SkeletonLoaderWrapper>
		<div style={{ width: '100%', height: '70px', backgroundColor: '#e0e0e0', borderRadius: '4px', marginBottom: '10px' }} />
		<div style={{ width: '100%', height: '70px', backgroundColor: '#e0e0e0', borderRadius: '4px', marginBottom: '10px' }} />
		<div style={{ width: '100%', height: '70px', backgroundColor: '#e0e0e0', borderRadius: '4px', marginBottom: '10px' }} />
	</Styled.SkeletonLoaderWrapper>
);

const AssignmentList = forwardRef((props: AssignmentListProps, ref) => {
	const location = useLocation();
	const manager = useInjection<GenericManager>(GenericManager);
	const { campaignId, ownerId, assignmentId } = useParams();

	if (!campaignId || !ownerId) {
		return <span>Invalid campaign or owner.</span>;
	}

	let path = '/campaigns/:campaignId/:ownerId/assignments/:assignmentId';

	const queryBuilder = RequestQueryBuilder.create()
		.withInclude('assignment')
		.withInclude('assignmentGroup')
		.withInclude('reviews')
		.withInclude('influencer.user')
		.withInclude('reviews.medias')
		.withInclude('reviews.pendingUploads')
		.withInclude('instagramPosts.screenshots')
		.withInclude('instagramStories.screenshots')
		.withInclude('instagramStories.frames');

	const { isLoading, repository, error, mutate } = manager.get(props.assignmentsUrl, queryBuilder);
	const instagramOwnerAssignments = repository?.findAll<CampaignInstagramOwnerAssignment>('campaignInstagramOwnerAssignment') ?? [];

	if (location.pathname.startsWith('/influencer')) {
		path = '/influencer' + path;
	}

	useImperativeHandle(ref, () => ({
		refreshAssignments: () => mutate(),
	}));

	// Track rendered statuses using a Record to avoid duplicate headings
	const renderedStatuses: Record<string, boolean> = {};

	return (
		<Styled.Wrapper className={classNames({ visible: true })}>
			<Styled.ListWrapper>
				<Styled.InnerWrapper>
					{/* Show skeleton loaders when loading */}
					{isLoading && <SkeletonLoader />}

					{/* Render the selected assignment with status heading */}
					{!isLoading &&
						props.assignments
							.sort(
								(a, b) =>
									ManagerCampaignInstagramOwnerStatusPriority(a.attributes.newFancyStatus) -
									ManagerCampaignInstagramOwnerStatusPriority(b.attributes.newFancyStatus),
							)
							.map((item, index) => {
								const assignment = repository?.findOneByRelation<Assignment, CampaignInstagramOwnerAssignment>(item, 'assignment');
								const reviews = repository?.findByRelation<Review, CampaignInstagramOwnerAssignment>(item, 'reviews');
								const latestReview = reviews?.length
									? first(reviews?.sort((a, b) => new Date(b.attributes.createdAt).getTime() - new Date(a.attributes.createdAt).getTime()))
									: null;

								if (isNil(assignment)) {
									return null;
								}

								const status = getStatus(item, latestReview as Review);

								// Add status heading only if not already rendered
								let statusHeading = null;
								if (!renderedStatuses[status]) {
									statusHeading = <Styled.Status>{CampaignInstagramOwnerStatusText(status)}</Styled.Status>;
									renderedStatuses[status] = true; // Mark this status as rendered
								}

								const active = item.id === assignmentId;
								const assignmentProps = {
									id: assignment.id,
									name: assignment.attributes?.name,
									type: assignment.attributes?.kind,
									status,
									isActive: active,
									disabled: active,
									user: props.user,
								};

								return (
									<React.Fragment key={index}>
										{statusHeading && <Heading as='h4'>{statusHeading}</Heading>}
										<Link
											data-testid={`assignment-${assignment.id}`}
											onClick={() => props.setIsExpanded(true)}
											to={generatePath(path, {
												campaignId,
												ownerId: ownerId,
												assignmentId: item.id,
											})}
											replace
										>
											<AssignmentItem {...assignmentProps} />
										</Link>
									</React.Fragment>
								);
							})}

					{/* Display message if no assignments found */}
					{!isLoading && instagramOwnerAssignments?.length === 0 && (
						<li className='no-assignments'>
							<span>No assignments found!</span>
						</li>
					)}
				</Styled.InnerWrapper>
			</Styled.ListWrapper>

			{/* Display error message if there's an error */}
			{error && <span>Cannot load assignments</span>}
		</Styled.Wrapper>
	);
});

export default AssignmentList;
