import classNames from 'classnames';
import { isNil } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Icon from 'components/Icon';
import { useAppSelector } from 'hooks/useUserAppSelector';
import colors from 'styles/theme/colors';

import Styled from './PageHeader.style';
import { IPageHeaderProps } from './types';

export const greetingPhrase = (curHr: number): string => {
	if (curHr < 12) {
		return 'Good morning';
	} else if (curHr < 18) {
		return 'Good afternoon';
	} else {
		return 'Good evening';
	}
};

/**
 * Page header
 * @param {IPageHeaderProps} props
 * @returns {JSX.Element}
 */
const PageHeader = (props: IPageHeaderProps): JSX.Element => {
	const [currentDate, setCurrentDate] = useState<string>(moment().format('HH:mm dddd, DD MMMM'));

	const { headline } = props;
	const user = useAppSelector((state) => state.user);
	const curHr = props.currentHour ? props.currentHour : new Date().getHours();

	useEffect(() => {
		props.showCurrentDate && dateInterval();
		return () => {
			setCurrentDate('');
		};
	}, []);

	const dateInterval = (): void => {
		setInterval(() => {
			setCurrentDate(moment().format('HH:mm dddd, DD MMMM'));
		}, 1000); // update each second
	};

	const getUserHeadlineGreeting = () => {
		if (user && user.name) {
			return `${greetingPhrase(curHr)}, ${user.name}`;
		}
		return greetingPhrase(curHr);
	};

	const Heading = () => {
		if (props.userGreeting) {
			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						fontSize: '16px',
						backgroundColor: colors.paleGreenTint,
						padding: '0.5rem 1rem',
						borderRadius: '10px',
						gap: '8px',
					}}
				>
					<Icon name='wave' />
					{getUserHeadlineGreeting()}
				</div>
			);
		}

		if (props.customHeadline) {
			return props.customHeadline; // JSX Element
		}

		return headline || '';
	};

	return (
		<Styled.Wrapper className={classNames(props.className)}>
			<Helmet>
				<title>{isNil(props.headline) ? 'Collabs' : `${props.headline} | Collabs.se`}</title>
			</Helmet>
			<Styled.HeaderBottom>
				<Styled.PageHeadingWrapper>
					<Styled.PageHeading data-testid='page-title'>{Heading()}</Styled.PageHeading>
				</Styled.PageHeadingWrapper>
				<Styled.ComponentContainer>{props.children}</Styled.ComponentContainer>
			</Styled.HeaderBottom>
			{props.showCurrentDate && (
				<Styled.DateWrapper>
					<Styled.DateText>{currentDate}</Styled.DateText>
				</Styled.DateWrapper>
			)}
		</Styled.Wrapper>
	);
};

PageHeader.defaultProps = {
	showBreadcrumb: true,
	showCurrentDate: false,
};

export default PageHeader;
