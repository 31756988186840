import { isNil } from 'lodash';
import { useMemo } from 'react';

import Icon from 'components/Icon';
import { IconSize } from 'components/Icon/types';
import Tooltip from 'components/Tooltip';

import Styled from './IconButton.style';

type Props = {
	children?: React.ReactNode;
	onClick?: (e: React.MouseEvent) => void;
	iconName?: string;
	iconSize?: IconSize;
	helpText?: string;
	className?: string;
	testId?: string;
	displayNotification?: boolean;
};

const IconButton = ({ children, onClick, iconName, iconSize = '20', helpText, testId, displayNotification }: Props) => {
	const content = useMemo(() => {
		if (isNil(children)) {
			return (
				<Styled.IconWrapper onClick={onClick} data-testid={testId}>
					{displayNotification ? <Styled.UnreadMark /> : null}
					<Icon name={iconName} size={iconSize} />
				</Styled.IconWrapper>
			);
		}
		return children;
	}, [children, onClick, iconName, iconSize, testId]);

	// Conditionally render Tooltip only if helpText exists
	if (helpText) {
		return (
			<Tooltip content={helpText} position='top' delayShow={200}>
				{content}
			</Tooltip>
		);
	}

	return content;
};

export default IconButton;
