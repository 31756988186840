import { Model } from 'json-api-models';
import moment from 'moment';

import ContentCard from 'components/ContentCard';
import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import useContentManagementData from 'components/ContentManagement/hooks';
import toast from 'services/Toast';
import { InstagramStory } from 'shared/Types/InstagramStories';

/**
 * List screenshots
 * Component to list single and multiple screenshots for the stats.
 *
 * @param {ScreenshotsProps} props
 * @returns {JSX.Element}
 */
const ListScreenshots = ({
	refresh,
	fetchedScreenShots,
	assignmentApproved,
}: {
	refresh?: () => Promise<void>;
	fetchedScreenShots: InstagramStory[] | Model[];
	assignmentApproved?: boolean;
}): JSX.Element => {
	const { deleteMedia } = useContentManagementData();

	const deleteFile = async (selectedFile: Model) => {
		await deleteMedia(selectedFile.links.delete)
			.then(() => {
				refresh && refresh();
				toast.success('File deleted');
			})
			.catch((e) => {
				console.error(e);
			});
	};

	return (
		<Styled.ListScreenShots>
			{// eslint-disable-next-line @typescript-eslint/no-explicit-any
			fetchedScreenShots?.map((screenshot: any, i) => {
				return (
					<Styled.StoryWrapper key={i}>
						<ContentCard imageUrl={screenshot.links.file} altText='Screenshot' active={assignmentApproved ? false : true} type='tall' />
						<Styled.RemoveFile>
							<p className='name'>Uploaded: {moment(screenshot.attributes.createdAt).format('YYYY-MM-DD')}</p>
							{screenshot.links.delete && (
								<p
									className='remove'
									onClick={() => {
										deleteFile(screenshot);
									}}
								>
									Delete
								</p>
							)}
						</Styled.RemoveFile>
					</Styled.StoryWrapper>
				);
			})}
		</Styled.ListScreenShots>
	);
};

export default ListScreenshots;
