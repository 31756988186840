import styled from 'styled-components';

import colors from 'styles/variables/colors';

const Wrapper = styled.div``;

const Header = styled.div`
	& > h3 {
		margin-bottom: 24px;
	}
	line-height: 150%;
`;

const ProfileSelectorWrapper = styled.div`
	& > h6 {
		&.title {
			font-size: 0.875rem;
			font-weight: 600;
			margin-top: 16px;
			margin-bottom: 16px;
		}
	}

	& > div {
		&.error-wrapper {
			margin-top: 16px;
			p {
				margin-top: 0;
			}
		}
		&.copy-button-wrapper {
			margin-top: 16px;
		}
	}
`;

const CopyProfileButton = styled.button`
	background-color: transparent;
	border: none;
	color: ${colors.info};
	font-weight: 500;
	font-size: 1rem;
`;

const Styled = {
	Wrapper,
	Header,
	ProfileSelectorWrapper,
	CopyProfileButton,
};

export default Styled;
