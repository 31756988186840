import { useNavigate } from 'react-router-dom';

import { HoverButton } from 'components/Button';
import Icon from 'components/Icon';
import Pill from 'components/Pill';
import { getInitials } from 'shared/User/User.helpers';

import Styled from './ClientItemCard.style';
import { ClientItemCardProps } from './types';

const ClientItemCard = ({ publisher, client, brands }: ClientItemCardProps) => {
	const navigate = useNavigate();

	const calculateRest = (brands: number) => {
		return brands > 5 ? brands - 5 : 0;
	};
	return (
		<Styled.ClientCard onClick={() => navigate(`/settings/clients/${client.id}`)}>
			<Styled.ClientName>{publisher?.attributes.name && <Pill title={publisher?.attributes.name} className='purple' />}</Styled.ClientName>
			<Styled.ClientCardTop>
				{client.links?.logo ? (
					<Styled.ClientLogo logo={client.links?.logo} />
				) : (
					<Styled.ClientLogo>
						<Styled.AddIcon role='button'>
							<Icon name='plus' size='12' />
						</Styled.AddIcon>
						<span>Add logo</span>
					</Styled.ClientLogo>
				)}
				<p>{client.attributes.name}</p>
			</Styled.ClientCardTop>
			<Styled.ContainerWrapper>
				<Styled.BrandContainer>
					{brands.length > 0 ? (
						brands.slice(0, 5).map((brand, key) => {
							return (
								<Styled.Brand
									key={key}
									logo={brand.links?.logo}
									onClick={(e) => {
										e.stopPropagation(), navigate(`/settings/brands/${brand.id}`);
									}}
								>
									{!brand.links?.logo ? <Styled.IconContainer>{getInitials(brand.attributes.name)}</Styled.IconContainer> : <Styled.Content />}
								</Styled.Brand>
							);
						})
					) : (
						<HoverButton
							useAddIcon
							onClick={(e) => {
								e.stopPropagation(), navigate(`/settings/clients/${client.id}`, { state: { create: true } });
							}}
						>
							Add brand
						</HoverButton>
					)}
				</Styled.BrandContainer>
				<Styled.CalculateRest>
					{calculateRest(brands.length) > 0 && (
						<>
							<Icon name='plus' size='12' /> <span style={{ marginLeft: '3px' }}>{calculateRest(brands.length)}</span>
						</>
					)}
					<Icon name='chevron-right' />
				</Styled.CalculateRest>
			</Styled.ContainerWrapper>
		</Styled.ClientCard>
	);
};

export default ClientItemCard;
