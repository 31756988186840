import styled from 'styled-components';

import { PrimaryButton } from 'components/Button';
import colors from 'styles/variables/colors';

const Wrapper = styled.div`
	padding-top: 1rem;
	padding-bottom: 1rem;
	width: 100%;
`;

const InnerWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Button = styled(PrimaryButton)`
	margin-bottom: 16px;
	font-size: 1rem;
`;

const HelpText = styled.span`
	font-size: 0.75rem;
	color: ${colors.dataLibrary.csvFileUpload.helpText};
`;

const Styled = {
	Wrapper,
	InnerWrapper,
	Button,
	HelpText,
};

export default Styled;
