import { isEmpty } from 'lodash';

import { TertiaryButton } from 'components/Button';
import { Heading } from 'components/Heading';
import Icon from 'components/Icon';
import usePermissions from 'hooks/usePermissions';
import toast from 'services/Toast';
import { AssignmentStatus } from 'shared/Types/Assignment';

import ReviewSection from './ReviewSection';
import SubmitSection from './SubmitSection';
import Styled from './Topbar.style';
import { TopbarProps } from './types';

const ManagerTopbar = ({
	hasJoined,
	medias,
	heading,
	status,
	permissions,
	loading,
	handlers,
	errors,
	files,
	CIOAssignment,
	latestReview,
	isValid,
	isEditing,
}: TopbarProps): JSX.Element => {
	const { isInfluencer } = usePermissions();

	const isInReviewButNoMediasAndNoPendingUploads =
		!isEditing &&
		!loading.isPosting &&
		status.newFancyStatus === AssignmentStatus.IN_REVIEW &&
		isEmpty(latestReview?.medias) &&
		isEmpty(latestReview?.pendingUploads);

	const displaySuccessMessage =
		status.newFancyStatus === AssignmentStatus.IN_REVIEW && isInfluencer() && !loading.isUploadingFiles && !isEmpty(latestReview.medias);

	const assignmentIsReviewable = !isInfluencer() && !isEditing && status.newFancyStatus === AssignmentStatus.IN_REVIEW;
	const assignmentDeclined = status.newFancyStatus === AssignmentStatus.DECLINED;

	const renderActionButtons = () => (
		<Styled.ActionsGroup>
			<Styled.TitleWrapper>
				<Heading as='h6'>{CIOAssignment.assignment.name}</Heading>
			</Styled.TitleWrapper>
			{!hasJoined && 'Profile has not yet joined the campaign'}
			<div className='buttons'>
				{permissions.canEdit && (
					<TertiaryButton onClick={isEditing ? handlers.onClickCancel : handlers.onClickEdit} data-testid='cm-edit'>
						{isEditing ? 'Cancel' : 'Edit'}
					</TertiaryButton>
				)}
				{permissions.canCreateReview && !isEditing && !assignmentIsReviewable && (
					<SubmitSection
						submitAction={medias?.length > 0 ? handlers.onClickOpenSendForReviewModal : () => toast.error('Your need to upload at least one image or video')}
						isLoading={loading.isSaving}
						title={heading}
						files={files}
						errors={errors}
						buttonText='Submit'
						testId='cm-save-content'
						className='fit-content'
						isValid={isValid}
						assignmentApproved={status.newFancyStatus === AssignmentStatus.APPROVED}
					/>
				)}
				{permissions.canEdit && isEditing && (
					<SubmitSection
						submitAction={handlers.onClickOpenSendForReviewModal}
						onClickEdit={handlers.onClickEdit}
						isEditing={isEditing}
						onClickCancel={handlers.onClickCancel}
						isLoading={loading.isSaving}
						title={heading}
						files={files}
						errors={errors}
						buttonText='Update and send'
						className='fit-content'
						testId='cm-save-changes'
						isValid={isValid}
						assignmentApproved={status.newFancyStatus === AssignmentStatus.APPROVED}
					/>
				)}
				{assignmentIsReviewable && (
					<ReviewSection
						errors={errors}
						title={heading}
						onClickRequestChanges={handlers.onClickRequestChanges}
						onClickApprove={handlers.onClickApprove}
						onClickClientApprove={handlers.onClickClientApprove}
						onClickEdit={handlers.onClickEdit}
						onClickCancel={handlers.onClickCancel}
						isEditing={isEditing}
						canRequestChanges={permissions.canRequestChanges}
						canApprove={permissions.canApprove}
						canEdit={permissions.canEdit}
						canClientApprove={permissions.canClientApprove}
						isClientApproveLoading={loading.isClientApproveLoading}
						hideErrors={permissions.canCreateReview}
						status={status.reviewStatus}
					/>
				)}
			</div>
		</Styled.ActionsGroup>
	);

	return (
		<Styled.Wrapper>
			<Styled.HeadingWrapper>
				<Styled.Heading id={'heading'} data-testid='cm-assignment-heading'>
					{!assignmentDeclined && renderActionButtons()}
				</Styled.Heading>
			</Styled.HeadingWrapper>

			{isInReviewButNoMediasAndNoPendingUploads && (
				<Styled.CompletedWrapper className='error'>
					<Icon name='circle-cross' size='32' />
					<Styled.Title>Sorry, something went wrong!</Styled.Title>
					<p>We got an unexpected error while your video/picture uploading process.</p>
					<p>It happens when the file size is too large or upload multiple videos.</p>
					<p>Please, click the Edit button and retry upload.</p>
				</Styled.CompletedWrapper>
			)}

			{displaySuccessMessage && (
				<>
					<Styled.CompletedWrapper>
						<Icon name='check-circle' size='32' />
						<Styled.Title>Nice, assignment submitted!</Styled.Title>
						<p>No worries, you’ll be notified when it has been reviewed.</p>
					</Styled.CompletedWrapper>
					<hr />
				</>
			)}
		</Styled.Wrapper>
	);
};

export default ManagerTopbar;
