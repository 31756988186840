import styled from 'styled-components';

import colors from 'styles/theme/colors';

const ClientDetails = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const ClientNameWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: baseline;
`;

const Name = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	width: fit-content;
	gap: 8px;
	h4 {
		margin-bottom: 0;
		white-space: nowrap;
	}
	span {
		margin-left: 5px;
	}
	&.border-bottom {
		border-bottom: 2px solid ${colors.smoke};
	}
	&.client-name {
		max-width: 500px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

const EditButtonWrapper = styled.div`
	width: 130px;
	min-width: 130px;
	right: 0;
	padding-top: 0.5rem;
`;

const InlineInput = styled.input`
	background: transparent;
	border: transparent !important;
	width: 100%;
	height: 40px;
	padding: 0;
	font-size: 1.2rem;
	font-weight: 700;
	&:focus:not(:disabled) {
		box-shadow: none;
	}
`;

const Margin = styled.div`
	margin: 0 8px;
`;

export default {
	ClientDetails,
	ClientNameWrapper,
	Name,
	EditButtonWrapper,
	InlineInput,
	Margin,
};
