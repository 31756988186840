import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SecondaryButton } from 'components/Button';
import AssignmentList from 'components/ContentManagement/Components/AssignmentList/AssignmentList';
import ContentSection from 'components/ContentManagement/Components/ContentSection';
import { useCampaignInstagramOwnerAssignments } from 'components/ContentManagement/hooks';
import InfluencerInfo from 'components/IntegratedInbox/Components/InfluencerInfo';
import { SideDrawer } from 'components/SideDrawer';
import usePermissions from 'hooks/usePermissions';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { getInstagramOwnerAssignmentsUrl, pathInfluencerCampaignView } from 'routing/PathLookup';

import SkeletonLoader from './components/SkeletonLoaders/SkeletonLoaders';
import { Props, RouteParams } from './types';

const CMSidebar = ({ campaign, campaigns, onClose, influencerId, AssignmentsListRef, selectedCIO, repository, brandManagerUsers = [] }: Props) => {
	const { useGetIOAS } = useCampaignInstagramOwnerAssignments();
	const [isExpanded, setIsExpanded] = useState(true);
	const { assignmentId, campaignId } = useParams<RouteParams>();

	const thisCampaignId = campaign?.attributes.shortId ?? campaignId;

	const url = useMemo(() => {
		return thisCampaignId && influencerId && getInstagramOwnerAssignmentsUrl(thisCampaignId, influencerId);
	}, [thisCampaignId, influencerId]);

	const { models, loading, mutation } = useGetIOAS(url);

	const influencer = models.findAll('influencer')[0] ?? null;
	const assignments = models.findAll('campaignInstagramOwnerAssignment');

	const campaignById = campaigns?.filter((campaign) => campaign.id === campaignId)[0] ?? undefined;
	const assignment = assignmentId ? models.find('campaignInstagramOwnerAssignment', assignmentId) : null;

	const user = useAppSelector((state) => state.user);
	const { isInfluencer } = usePermissions();

	const thisCampaign = campaign ?? campaignById; // If opened from influencer dashboard

	return (
		<SideDrawer
			sidebarIsOpen={true}
			isExpandable
			blockClickOutside
			title='Content management'
			saveButtonText='Save'
			isExpanded={isExpanded}
			expandedTitle={isInfluencer() ? assignment?.assignment.attributes.name : <InfluencerInfo influencer={influencer} />}
			onClose={() => {
				onClose();
				setIsExpanded(false);
			}}
			customButtons={<></>}
			setNotExpanded={() => setIsExpanded(false)}
			dataTestId='sidebar-cm'
			expandedContent={
				loading ? (
					<SkeletonLoader variant='content' />
				) : (
					thisCampaign &&
					selectedCIO && (
						<ContentSection
							brandManagerUsers={brandManagerUsers}
							selectedCampaign={thisCampaign}
							CIOAssignment={assignment}
							selectedCIO={selectedCIO}
							repository={repository}
							CIOAS={assignments}
							CIOArefresh={() => mutation.refresh()}
							refresh={() => mutation.refresh()}
						/>
					)
				)
			}
		>
			<>
				{loading ? (
					<SkeletonLoader variant={isInfluencer() ? 'influencer' : 'manager'} />
				) : (
					<>
						{isInfluencer() && thisCampaign && (
							<div className='mt-24'>
								<SecondaryButton onClick={() => window.open(pathInfluencerCampaignView(thisCampaign.attributes.shortId, influencer.id), '_blank')}>
									View campaign brief
								</SecondaryButton>
							</div>
						)}
						{url && <AssignmentList ref={AssignmentsListRef} assignmentsUrl={url} assignments={assignments} user={user} setIsExpanded={setIsExpanded} />}
					</>
				)}
			</>
		</SideDrawer>
	);
};

export default CMSidebar;
